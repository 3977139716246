
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.PrintHeader {
  margin-bottom: 2rem;
  background-color: black;
  color: white;

  // Force background colours to print: stackoverflow.com/a/45944620
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;

  table {
    width: 100%;
    border-spacing: 1em;
  }

  tbody {
    vertical-align: top;
  }

  .heading {
    font-weight: normal;
    font-size: 1.75em;
  }

  .col-logo {
    overflow: hidden;
    width: 45px;
    max-width: 45px;
  }

  .col-student {
    padding-left: 6%;
    text-align: right;

    .heading {
      word-break: break-all;
    }
  }

  .logo-dark {
    display: none;
  }
}

// Styles for IE (hack from browserhacks.com)
@media screen and (min-width: 0\0), print and (min-width: 0\0) {
  .PrintHeader {
    margin-bottom: 2rem;
    max-width: 100vw;
    background-color: transparent;
    color: black;

    .logo {
      height: 49px;
    }

    .logo-light {
      display: none;
    }

    .logo-dark {
      display: block;
    }
  }
}
