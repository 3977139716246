
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppSnackbar .v-snack {
  font-size: 16px;
  color: #121212;
}
::v-deep .v-snack__content {
  padding: 16px 16px;
}

::v-deep .v-snack__wrapper {
  max-width: 900px !important;
}
